import React from 'react'

export const questionListSeksolog = [
  {
    labelGA: 'FormatSeksolog',
    ask: 'В каком формате удобнее проходить консультацию с сексологом?',
    ans: () => (
      <>
        <p>
          Прием сексолога онлайн обычно комфортнее для клиента: вы находитесь в
          своём пространстве, и при должной подготовке можете быть уверены, что
          вас никто не слышит — это позволяет вести себя более расслабленно.
        </p>
        <br />
        <p>
          Вы можете выбрать наиболее удобный формат общения: текстовые
          сообщения, аудиозаписи или видеозвонок, в зависимости от ваших
          предпочтений и комфорта.
        </p>
      </>
    )
  },
  {
    labelGA: 'PrivacySeksolog',
    ask: 'Насколько сессия с сексологом конфиденциальна?',
    ans: () => (
      <>
        <p>
          В YouTalk мы придерживаемся строгих принципов конфиденциальности. Все
          ваши данные и разговоры с психологом-сексологом остаются абсолютно
          конфиденциальными и не разглашаются третьим лицам.
        </p>
        <br />
        <p>
          Вы можете быть уверены в сохранении полной приватности и безопасности
          во время сессии.
        </p>
      </>
    )
  },
  {
    labelGA: 'NeedToPrepareSeksolog',
    ask: 'Как и нужно ли готовиться к консультации сексолога?',
    ans: () => (
      <>
        <p>
          Перед онлайн консультацией сексолога стоит задуматься над тем, что
          именно вы хотели бы обсудить или решить.
        </p>
        <br />
        <p>
          Вы можете подготовить вопросы, записать свои мысли или проблемы,
          которые хотите обсудить. Это поможет вам сориентироваться и
          максимально эффективно использовать время во время сессии с
          сексологом.
        </p>
      </>
    )
  },
  {
    labelGA: 'DoYouNeedBringPartnerSeksolog',
    ask: 'Стоит ли брать на онлайн консультацию сексолога партнера?',
    ans: () => (
      <>
        <p>
          При проблемах в сексуальной сфере, вовлечение партнера в консультацию
          может быть полезным.
        </p>
        <br />
        <p>
          Обсуждение проблем и поиск решений вместе с партнером может
          способствовать лучшему пониманию и совместной работе над проблемой.
          Однако, это зависит от ваших индивидуальных предпочтений и ситуации.
          Вы всегда можете обсудить это с психологом-сексологом и принять
          решение, насколько вам будет удобно включать партнера в консультацию.
        </p>
      </>
    )
  },
  {
    labelGA: 'NeedToBeHonestSeksolog',
    ask: 'Насколько нужно быть откровенным во время сессии?',
    ans: () => (
      <>
        <p>
          Важно быть откровенным и честным во время онлайн сессии сексолога. Чем
          более подробно и откровенно вы сможете рассказать о своих проблемах,
          мыслях и ощущениях, тем лучше психолог-сексолог сможет вам помочь.
        </p>
        <br />
        <p>
          Помните, что психолог является профессионалом и относится к вашей
          ситуации с пониманием и уважением. Вы можете быть уверены, что ваша
          откровенность будет встречена с пониманием и без осуждения.
        </p>
      </>
    )
  },
  {
    labelGA: 'WhyShouldTrustSeksolog',
    ask: 'Почему я должен доверять вашим специалистам?',
    ans: () => (
      <>
        <p>
          YouTalk гарантирует высокий уровень профессионализма и качества своих
          специалистов.
        </p>
        <br />
        <p>
          Все психологи-сексологи проходят строгий отбор и проверку перед
          присоединением к нашей команде.
        </p>
        <br />
        <p>
          Мы работаем только с высококвалифицированными и опытными
          специалистами, которые обладают необходимыми знаниями и навыками,
          чтобы помочь вам в решении ваших сексуальных вопросов и проблем.
        </p>
      </>
    )
  },
  {
    labelGA: 'WhatIfIAshamedSeksolog',
    ask: 'Что делать, если мне стыдно говорить с сексологом?',
    ans: () => (
      <>
        <p>
          Стыд или неловкость при общении с сексологом — это естественные
          чувства, которые могут возникнуть.
        </p>
        <br />
        <p>
          Важно понимать, что психолог-сексолог работает с различными
          сексуальными вопросами и проблемами каждый день и встречает людей,
          которые чувствуют подобные эмоции. Они готовы вас поддержать и помочь
          преодолеть эти чувства.
        </p>
        <br />
        <p>
          Постепенно, в процессе работы с психологом, вы можете почувствовать
          большую комфортность и открытость в общении.
        </p>
      </>
    )
  }
]
